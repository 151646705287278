import Vue from 'vue'
import VueI18n from 'vue-i18n'
import sl from './locales/sl.json'
import en from './locales/en.json'
import hr from './locales/hr.json'

const messages = {
  en,
  sl,
  hr
}

Vue.use(VueI18n)

export default new VueI18n({
  locale: 'sl', // set default locale
  fallbackLocale: 'sl',
  messages,
  availableLocales: ['sl', 'en', 'hr']
})
